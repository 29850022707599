// fe-LabComponents/src/admin/AnaliseTecnicaModal.jsx
import React, { useState, useEffect } from 'react';
import './AnaliseTecnicaModal.css';
import IAPanel from './IAPanel'; // Importa o painel de IA
import axios from 'axios';
import Swal from 'sweetalert2';
import FileUpload from './FileUpload';

const AnaliseTecnicaModal = ({ isOpen, handleClose, analise }) => {
  const [status, setStatus] = useState('');
  const [showEditorModal, setShowEditorModal] = useState(false); 
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [analysisText, setAnalysisText] = useState('');
  const [isIaPanelOpen, setIaPanelOpen] = useState(false);

  useEffect(() => {
    if (analise?.analysisData && analise.analysisData[0]?.status) {
      setStatus(analise.analysisData[0].status);
    }
  }, [analise]);

  useEffect(() => {
    if (analise?.status) {
      setStatus(analise.status); // Sincroniza o status inicial com o valor do banco de dados
    }

    if (analise?.technicalAnalysisReport) {
      setAnalysisText(analise.technicalAnalysisReport); // Sincroniza o texto da análise com o valor do banco de dados
    }
  }, [analise]);

  if (!isOpen) return null; 

  // Função para abrir o editor de análise técnica
  const handleOpenEditor = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${analise._id}/report`);
      setAnalysisText(response.data.technicalAnalysisReport || ''); // Atualiza o estado com o relatório técnico do banco de dados
      setShowEditorModal(true); // Abre o modal
    } catch (error) {
      console.error('Erro ao obter o relatório técnico:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Erro ao obter relatório técnico.',
        confirmButtonText: 'Tentar novamente'
      });
    }
  };
  // Função para fechar o editor de análise técnica
  const handleCloseEditor = () => setShowEditorModal(false);

  // Função para salvar o status no backend ao alterar
  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus); // Atualiza o estado localmente
  
    try {
      if (!analise._id) {
        console.error('ID da análise não disponível');
        return;
      }
  
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${analise._id}/status`, { status: newStatus });
      
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Status atualizado com sucesso!',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Erro ao atualizar status.',
        confirmButtonText: 'Tentar novamente'
      });
    }
  };

  // Função para lidar com o upload de arquivos
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleFileUpload = async () => {
    if (uploadedFiles.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Nenhum arquivo selecionado.',
        confirmButtonText: 'OK'
      });
      return;
    }

    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append('document', file); // Nome do campo deve ser 'document' para corresponder à rota backend
    });

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${analise._id}/upload-document`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Arquivos enviados com sucesso!',
        confirmButtonText: 'OK'
      });

      // Atualiza os arquivos enviados com o retorno do backend (se necessário)
      setUploadedFiles([...uploadedFiles, ...response.data.files]);
    } catch (error) {
      console.error('Erro ao enviar arquivos:', error);
      
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Erro ao enviar arquivos.',
        confirmButtonText: 'Tentar novamente'
      });
    }
  };

  // Função para salvar o relatório técnico no backend
  const handleSaveAnalysisText = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${analise._id}/report`, { technicalAnalysisReport: analysisText });

      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Relatório técnico salvo com sucesso!',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Erro ao salvar relatório técnico:', error);
      
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Erro ao salvar relatório técnico.',
        confirmButtonText: 'Tentar novamente'
      });
    }
  };

  return (
    <div className="analise-modal-overlay" onClick={handleClose}>
      <div className="analise-modal" onClick={(e) => e.stopPropagation()}>
        <h2>Avaliação  Técnica</h2>

        {/* Seletor de Status */}
        <div className="status-section">
          <label htmlFor="status">Status da Análise:</label>
          <select id="status" value={status} onChange={handleStatusChange}>
            <option value="Em espera">Em espera</option>
            <option value="Em andamento">Em andamento</option>
            <option value="Concluída">Concluída</option>
          </select>
        </div>

        {/* Editor de Análise */}
        <div className="editor-section">
          <h3>Análise Técnica</h3>
          <button className="editor-button" onClick={handleOpenEditor}>
            Abrir Editor de Análise
          </button>
        </div>


      <FileUpload analiseId={analise?._id} />

        {/* Exportar Análise */}
        <div className="export-section">
          <h3>Exportar Análise</h3>
          <button className="export-button" onClick={() => Swal.fire({
            icon: 'info',
            title: 'Escolha',
            text: 'Escolha PDF ou DOCX',
            confirmButtonText: 'OK'
          })}>
            Exportar como PDF/DOCX
          </button>
        </div>

        {/* Painel de IA */}
   <button onClick={() => setIaPanelOpen(true)}>Abrir Painel de IA</button>
          <IAPanel 
            isOpen={isIaPanelOpen} 
            handleClose={() => setIaPanelOpen(false)} 
            analiseId={analise?._id}
          />  
        {/* Editor de Análise Modal */}
        {showEditorModal && (
          <div className="editor-modal-overlay" onClick={handleCloseEditor}>
            <div className="editor-modal" onClick={(e) => e.stopPropagation()}>
              <h2>Análise Técnica</h2>
              <textarea
                value={analysisText}
                onChange={(e) => setAnalysisText(e.target.value)}
                placeholder="Escreva sua análise técnica aqui..."
                rows="20"
                cols="50"
              />
              <div className="modal-buttons">
                <button onClick={handleSaveAnalysisText}>Salvar Análise</button>
                <button onClick={handleCloseEditor}>Fechar</button>
              </div>
            </div>
          </div>
        )}

        {/* Botão de Fechar */}
        <button onClick={handleClose} className="close-button">Fechar</button>
      </div>
    </div>
  );
};

export default AnaliseTecnicaModal;
