import React from 'react';
import { useNavigate } from 'react-router-dom'; // Para redirecionamento

const CustomIcon = () => {
  const navigate = useNavigate();

  return (
    <div className="custom-icon" onClick={() => navigate('/admin')}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 24 24"
        style={{ fill: '#00c3ff' }}
      >
        <path d="M7 22a4.965 4.965 0 0 0 3.535-1.465l9.193-9.193.707.708 1.414-1.414-8.485-8.486-1.414 1.414.708.707-9.193 9.193C2.521 14.408 2 15.664 2 17s.521 2.592 1.465 3.535A4.965 4.965 0 0 0 7 22zM18.314 9.928 15.242 13H6.758l7.314-7.314 4.242 4.242z"></path>
      </svg>
    </div>
  );
};

export default CustomIcon;
