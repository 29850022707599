import React from 'react';
import Notifications from './Notifications/Notifications.jsx';
import Exit from './Notifications/Exit.jsx';
import CustomIcon from './Notifications/CustomIcon.jsx';
import './Nav.css'; 

const Nav = () => {
  return (
    <div className="nav-container">
      <CustomIcon /> 
      <Notifications />
      <Exit />
    </div>
  );
};

export default Nav;
