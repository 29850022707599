import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Exemplo de chamada para buscar notificações do admin
    const fetchNotifications = async () => {
      // try {
      //   const response = await axios.get('/api/admin/notifications');
      //   setNotifications(response.data);
      // } catch (error) {
      //   console.error('Erro ao carregar notificações', error);
      // }
    };

    fetchNotifications();
  }, []);

  return (
    <div className="notifications-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style={{ fill: '#00c3ff' }}>
        <path d="M12 22c1.104 0 2-.897 2-2h-4c0 1.103.896 2 2 2zm6-6v-5c0-3.074-1.64-5.639-4.5-6.32V4c0-.827-.673-1.5-1.5-1.5S10.5 3.173 10.5 4v.68C7.64 5.361 6 7.926 6 11v5l-1 1v1h14v-1l-1-1z"></path>
      </svg>
      <div className="notification-list">
        {notifications.length === 0 ? (
          // <p>Sem novas notificações</p>
          <p></p>
        ) : (
          notifications.map((notification, index) => (
            <p key={index}>{notification.message}</p>
          ))
        )}
      </div>
    </div>
  );
};

export default Notifications;
