import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import LoginPage from '../pages/Login';
import ResetPassword from '../components/Login/ResetPassword';
import SignUp from '../components/Login/SignUp';
import Hall from '../pages/Hall';
import MinhasAnalises from '../pages/MinhasAnalises';
import NovaAnalise from '../pages/NovaAnalise';
import Chat from '../pages/Chat';
import ProtectedRoute from '../components/ProtectedRoute';
import Exit from '../components/Notifications/Exit';
import AnaliseFull from '../pages/AnaliseFull';
import ProtectedAdminRoute from '../admin/ProtectedAdminRoute'; 
import AdminLogin from '../admin/AdminLogin';
import AdminPanel from '../admin/AdminPanel'; 
import Nav from '../components/Nav';
import AdminNav from  '../admin/AdminNav'
import { useAuth } from '../hooks/useAuth';

const Loading = () => (
  <div className="loading">Loading...</div>
);

const AppRoutes = () => {
  const { loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        
        {/* Usuário comum */}
        <Route
          path="/hall"
          element={
            <ProtectedRoute>
              <Nav />
              <Hall />
            </ProtectedRoute>
          }
        />
        <Route
          path="/minhas-analises"
          element={
            <ProtectedRoute>
              <Nav />
              <MinhasAnalises />
            </ProtectedRoute>
          }
        />
        <Route
          path="/logout"
          element={
            <ProtectedRoute>
              <Nav />
              <Exit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/nova-analise"
          element={
            <ProtectedRoute>
              <Nav />
              <NovaAnalise />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <Nav />
              <Chat />
            </ProtectedRoute>
          }
        />
        <Route
          path="/analise-full/:id"
          element={
            <ProtectedRoute>
              <Nav />
              <AnaliseFull />
            </ProtectedRoute>
          }
        />
        
        {/* Admin */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route
          path="/admin"
          element={
            <ProtectedAdminRoute>
              <AdminNav />
              <AdminPanel />
            </ProtectedAdminRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;