import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AnaliseTecnicaModal from './AnaliseTecnicaModal';
import './AnaliseFullByAdmin.css'; 

const AnaliseFullByAdmin = ({ selectedAnalysis, handleBackToList }) => {
  const [analise, setAnalise] = useState(selectedAnalysis || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showChatModal, setShowChatModal] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [mensagem, setMensagem] = useState('');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showTechModal, setShowTechModal] = useState(false);
  useEffect(() => {
    const fetchAnalise = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${selectedAnalysis._id}`);
        setAnalise(response.data);
        setLoading(false);
      } catch (error) {
        setError('Erro ao carregar a análise.');
        setLoading(false);
      }
    };

    const fetchChat = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${selectedAnalysis._id}/chat`);
        setChatMessages(response.data);
      } catch (error) {
        console.error('Erro ao carregar o chat:', error);
      }
    };

    if (selectedAnalysis) {
      fetchAnalise();
      fetchChat();
    }
  }, [selectedAnalysis]);

  const handleSendMessage = async () => {
    if (mensagem.trim() === '') return;

    const newMessage = {
      sender: 'analista', 
      message: mensagem,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/analises/${selectedAnalysis._id}/chat`, newMessage);
      setChatMessages(response.data);
      setMensagem(''); 
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="analise-full-container">
      {/* Cabeçalho com o botão de voltar */}
      <div className="analise-header">
        <svg
          className="back-icon"
          onClick={handleBackToList} 
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ fill: 'rgba(0, 0, 0, 1)' }}
        >
          <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"></path>
        </svg>
      </div>
      
      <div className="analise-info">
        <h1>{analise.title}</h1>
        {analise.analysisData[0].samplePhoto && (
          <img 
            className="analise-image" 
            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${analise.analysisData[0].samplePhoto}`} 
            alt="Foto da Amostra" 
          />
        )}
        <p><strong>Tipo de Amostra:</strong> {analise.analysisData[0].sampleType}</p>
        <p><strong>Responsável:</strong> {analise.analysisData[0].responsibleName}</p>
        <p><strong>Data de Coleta:</strong> {new Date(analise.analysisData[0].collectionDate).toLocaleDateString()}</p>
        <p><strong>Geolocalização:</strong> {analise.analysisData[0].geolocation || 'Não especificada'}</p>
        <p><strong>Armazenamento:</strong> {analise.analysisData[0].storageType || 'Não especificado'}</p>
        <p><strong>Observações:</strong> {analise.analysisData[0].additionalObservations || 'Nenhuma observação'}</p>
        <p><strong>Aplicação do Resultado:</strong> {analise.analysisData[0].analysisApplication || 'Não especificado'}</p>
        <p><strong>Pré-tratamento:</strong> {analise.analysisData[0].preTreatment || 'Não especificado'}</p>
        <p><strong>Volume ou Massa da Amostra:</strong> {analise.analysisData[0].sampleVolumeOrMass || 'Não especificado'}</p>
        <p><strong>Aspecto da Amostra:</strong> {analise.analysisData[0].sampleAspect || 'Não especificado'}</p>
        <p><strong>Identificação no Laboratório:</strong> {analise.analysisData[0].labIdentification || 'Não especificado'}</p>
        <p><strong>Descrição do Ensaio/Experimento:</strong> {analise.analysisData[0].experimentDescription || 'Não especificado'}</p>
      </div>

      {/* Botões de Ações */}
      <div className="analise-info2">
        <button onClick={() => setShowInfoModal(true)}>
          Informações Adicionais
        </button>
        <button onClick={() => setShowTechModal(true)}>Avaliação Analítica</button>

        <div className="new-svg" onClick={() => setShowChatModal(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" style={{ fill: '#007bff' }}>
            <path d="M16 14h.5c.827 0 1.5-.673 1.5-1.5v-9c0-.827-.673-1.5-1.5-1.5h-13C2.673 2 2 2.673 2 3.5V18l5.333-4H16zm-9.333-2L4 14V4h12v8H6.667z"></path>
            <path d="M20.5 8H20v6.001c0 1.1-.893 1.993-1.99 1.999H8v.5c0 .827.673 1.5 1.5 1.5h7.167L22 22V9.5c0-.827-.673-1.5-1.5-1.5z"></path>
          </svg>
        </div>
      </div>
      <AnaliseTecnicaModal
        isOpen={showTechModal}
        handleClose={() => setShowTechModal(false)}
        analise={analise}
      />
      {/* Modal de Informações Adicionais */}
      {showInfoModal && (
        <div className="info-modal-overlay" onClick={() => setShowInfoModal(false)}>
          <div className="info-modal" onClick={(e) => e.stopPropagation()}>
            <h2>Informações Adicionais</h2>
            {selectedAnalysis.analysisData[0].additionalInfo.length > 0 ? (
              selectedAnalysis.analysisData[0].additionalInfo.map((info, index) => (
                <div key={index}>
                  <h3>{info.title}</h3>
                  <p>{info.content}</p>
                </div>
              ))
            ) : (
              <p>Não há informações adicionais disponíveis.</p>
            )}
            <button onClick={() => setShowInfoModal(false)}>Fechar</button>
          </div>
        </div>
      )}

      {/* Modal de Chat Direto */}
      {showChatModal && (
        <div className="chat-modal-overlay" onClick={() => setShowChatModal(false)}>
          <div className="chat-modal" onClick={(e) => e.stopPropagation()}>
            <div className="chat-body">
              <h2>Chat com o Analista</h2>
              {chatMessages.length > 0 ? (
                chatMessages.map((msg, index) => (
                  <div key={index} className={`chat-message ${msg.sender}`}>
                    <p>{msg.message}</p>
                    <span>{new Date(msg.timestamp).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' })}</span>
                  </div>
                ))
              ) : (
                <p>Nenhuma mensagem ainda.</p>
              )}
            </div>
            <div className="chat-footer">
              <input
                type="text"
                placeholder="Digite sua mensagem"
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}
              />
              <button onClick={handleSendMessage}>Enviar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnaliseFullByAdmin;
