import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StandardPrompt from './standardPrompt/StandardPrompt'; 
import avatar1 from './standardPrompt/avatar/avatar1.png';
import avatar2 from './standardPrompt/avatar/avatar2.png';
import avatar3 from './standardPrompt/avatar/avatar3.png';
import Prompt from './Prompt'; 

const PromptSection = () => {
  const [showCreatePromptModal, setShowCreatePromptModal] = useState(false);
  const [backendPrompts, setBackendPrompts] = useState([]); 

  
  const standardPrompts = [
    {
      image: avatar1,
      title: 'Prompt 1',
      text: 'Este é o primeiro prompt padrão.',
    },
    {
      image: avatar2,
      title: 'Prompt 2',
      text: 'Este é o segundo prompt padrão.',
    },
    {
      image: avatar3,
      title: 'Prompt 3',
      text: 'Este é o terceiro prompt padrão.',
    },
  ];

  
  useEffect(() => {
    const fetchPrompts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/prompts`);
        setBackendPrompts(response.data);
      } catch (error) {
        console.error('Erro ao buscar prompts:', error);
      }
    };

    fetchPrompts(); 
  }, []);

  

  const handleEditPrompt = (updatedPrompt) => {
    setBackendPrompts((prevPrompts) =>
      prevPrompts.map((prompt) =>
        prompt._id === updatedPrompt._id ? updatedPrompt : prompt
      )
    );
  };


  const handleSavePrompt = async (newPrompt) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/prompts`, newPrompt);
      setBackendPrompts((prevPrompts) => [...prevPrompts, response.data.prompt]);
    } catch (error) {
      console.error('Erro ao salvar prompt:', error.message);
    }
  };

  
  return (
    <div className="prompts-section">
      <h3>Prompts</h3>
      <div className="prompts-container">
        {/* Exibe prompts padrão */}
        {standardPrompts.map((prompt, index) => (
          <StandardPrompt
            key={index}
            image={prompt.image}
            title={prompt.title}
            text={prompt.text}
          />
        ))}

        {/* Exibe prompts do backend */}
        {backendPrompts.map((prompt) => (
            <StandardPrompt
              key={prompt._id}
              id={prompt._id}
              image={prompt.avatar}
              title={prompt.promptName}
              text={prompt.promptCommand}
              onEdit={handleEditPrompt} // Atualiza os prompts
              onUpdate={() => setBackendPrompts((prev) => prev.filter((p) => p._id !== prompt._id))} // Remove após exclusão
            />
          ))}
      </div>

      {/* Botão para abrir o modal de criar novo prompt */}
      <button className="open-modal-btn" onClick={() => setShowCreatePromptModal(true)}>Criar Novo Prompt</button>

      {/* Modal de criação de prompt */}
      <Prompt
        isOpen={showCreatePromptModal}
        onClose={() => setShowCreatePromptModal(false)}
        onSave={handleSavePrompt}
      />
    </div>
  );
};

export default PromptSection;
